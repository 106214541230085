.color-primary {
    color: #292929;
}

.color-secondary {
    color: #616161;
}

.color-3 {
    color: #EFEFEF;
}

body {
    background-color: #EFEFEF;
    color: #292929;
}
.navbar {
    margin-bottom: 1em;
}

.navbar ul {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    background-color: #616161;
}

.navbar li {
    float: left;
    margin-bottom: 0;
}

.navbar li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.navbar li a:hover {
    background-color: #ab5dda;
}

.navbar li .active {
    font-weight: bold;
}
