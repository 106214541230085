.card {
    display: inline-flex;
    width: 16em;
    margin: 0 0 1em 0;
}

.card-item {
    display: block;
    padding: 0 0 0 0.25em;

}

.card-list {
    display: block;
    padding: 0 0 0 0.5em;
    margin: 0.25em 0 0.5em 0;
    text-decoration: underline;
}

.icon-display {
    font-size: smaller;
    margin-left: 0.33em;
    color: #292929;
}

.card-content {
    display: block;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
    /*box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);*/
}

.page-title {
    margin: 0.25em 0 0 0.5em;
    font-size: 2.8rem;
}
